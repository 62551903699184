<template>
    <div style="width: 100%;padding: 0px 18px; background: linear-gradient(
            180deg,#fff,rgba(241,239,255,.49125) 53.12%,rgba(220,213,255,.262778) 80.73%,rgba(127,102,255,.08));">
        <div style="max-width: 1200px; width: 100%;margin: auto;">

            <h3 style="font-size: 34px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 0;
    text-align: left;">为什么选择 <mark style="padding: 0;
    color: #7f66ff;
    background-color: transparent; white-space: nowrap;">AiSight</mark></h3>
        </div>
        <div style="max-width: 1200px; width: 100%;margin: auto;">

            <a-row :gutter="32" justify="center" align="middle"
                style="padding-top: 20px;padding-bottom: 20px;padding-left: 12px;padding-right: 12px; ">

                <a-col style="max-width: 400px;" :span="8" :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="8">
                    <div class="advantage">
                        <div style="display: inline-block;width: 75px;">
                            <img src="../assets/iconEasy.png" style="width: 75px;" />
                        </div>
                        <div style="display: inline-block; width: calc(100% - 75px);
                        margin-top: -2px;
                            padding-left: 20px;text-align: left;vertical-align: top;">
                            <div style="font-size: 1.25rem;line-height: 1.75rem;">
                                易用性更好
                            </div>
                            <div
                                style="margin-top: 8px; font-size: 1rem;line-height: 1.75rem;color: rgba(25,20,51,.8);">
                                可视化操作，提供多种行业模板和工具，快速上手。
                            </div>
                        </div>
                    </div>
                </a-col>

                <a-col style="max-width: 400px;" :span="8" :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="8">
                    <div class="advantage">
                        <div style="display: inline-block;width: 75px;">
                            <img src="../assets/iconSupport.png" style="width: 75px;" />
                        </div>
                        <div style="display: inline-block; width: calc(100% - 75px);
                        margin-top: -2px;
                            padding-left: 20px;text-align: left;vertical-align: top;">
                            <div style="font-size: 1.25rem;line-height: 1.75rem;">
                                全面服务支持
                            </div>
                            <div
                                style="margin-top: 8px; font-size: 1rem;line-height: 1.75rem;color: rgba(25,20,51,.8);">
                                提供技术培训、项目调试、功能定制等专业服务。
                            </div>
                        </div>
                    </div>
                </a-col>

                <a-col style="max-width: 400px;" :span="8" :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="8">
                    <div class="advantage">
                        <div style="display: inline-block;width: 75px;">
                            <img src="../assets/iconPro.png" style="width: 75px;" />
                        </div>
                        <div style="display: inline-block; width: calc(100% - 75px);
                        margin-top: -2px;
                            padding-left: 20px;text-align: left;vertical-align: top;">
                            <div style="font-size: 1.25rem;line-height: 1.75rem;">
                                AiSight Pro
                            </div>
                            <div
                                style="margin-top: 8px; font-size: 1rem;line-height: 1.75rem;color: rgba(25,20,51,.8);">
                                集成一体式的控制器硬件，避免现场设备差异的影响。
                            </div>
                        </div>
                    </div>
                </a-col>

                <a-col style="max-width: 400px;" :span="8" :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="8">
                    <div class="advantage">
                        <div style="display: inline-block;width: 75px;">
                            <img src="../assets/iconBrand.png" style="width: 75px;" />
                        </div>
                        <div style="display: inline-block; width: calc(100% - 75px);
                        margin-top: -2px;
                            padding-left: 20px;text-align: left;vertical-align: top;">
                            <div style="font-size: 1.25rem;line-height: 1.75rem;">
                                多品牌相机适配
                            </div>
                            <div
                                style="margin-top: 8px; font-size: 1rem;line-height: 1.75rem;color: rgba(25,20,51,.8);">
                                适配各个品牌的相机，使用一套软件兼容全厂的设备。
                            </div>
                        </div>
                    </div>
                </a-col>

                <a-col style="max-width: 400px;" :span="8" :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="8">
                    <div class="advantage">
                        <div style="display: inline-block;width: 75px;">
                            <img src="../assets/iconDoc.png" style="width: 75px;" />
                        </div>
                        <div style="display: inline-block; width: calc(100% - 75px);
                        margin-top: -2px;
                            padding-left: 20px;text-align: left;vertical-align: top;">
                            <div style="font-size: 1.25rem;line-height: 1.75rem;">
                                丰富的资料和教程
                            </div>
                            <div
                                style="margin-top: 8px; font-size: 1rem;line-height: 1.75rem;color: rgba(25,20,51,.8);">
                                使用手册、视频教程，还有内嵌的操作说明，为调试保驾护航。
                            </div>
                        </div>
                    </div>
                </a-col>

                <a-col style="max-width: 400px;" :span="8" :xs="24" :sm="24" :md="12" :lg="12" :xl="8" :xxl="8">
                    <div class="advantage">
                        <div style="display: inline-block;width: 75px;">
                            <img src="../assets/iconFree.png" style="width: 75px;" />
                        </div>
                        <div style="display: inline-block; width: calc(100% - 75px);
                        margin-top: -2px;
                            padding-left: 20px;text-align: left;vertical-align: top;">
                            <div style="font-size: 1.25rem;line-height: 1.75rem;">
                                免费试用
                            </div>
                            <div
                                style="margin-top: 8px; font-size: 1rem;line-height: 1.75rem;color: rgba(25,20,51,.8);">
                                基础版本免费使用，完整版30天试用。
                            </div>
                        </div>
                    </div>
                </a-col>

            </a-row>
        </div>
    </div>
</template>

<script>


export default {
    name: 'HomeSecondCard',
    components: {
    }
}
</script>
<style>
.advantage {
    width: 100%;
    background-color: red;
    height: 160px;
    padding: 1.75rem;
    margin-top: 12px;
    margin-bottom: 12px;
    border: 1px solid rgba(235, 235, 255, .8);
    box-shadow: 0 2px 8px rgb(64 51 128 / 2%);
    border-radius: 16px;
    background: #fff;
}
</style>