<!-- Home.vue -->
<template>

    <a-row :gutter="16" justify="center" style="padding-left: 24px;padding-right: 24px;">
        <a-col :style="screens.lg ? { maxWidth: '200px' } : {}" :span="6" :xs="9" :sm="9" :md="6" :lg="4" :xl="4"
            :xxl="4">
            <h1 style="margin: 0;text-align: left;">
                <img alt="logo" style="height: 32px; position: relative;
    top: -1.5px;margin-right: 10px;vertical-align: middle;border-style: none;" src="../assets/logo.png" />
                AiSight
            </h1>
        </a-col>
        <a-col :style="screens.lg ? { maxWidth: '1000px' } : {}" :span="18" :xs="15" :sm="15" :md="18" :lg="20" :xl="20"
            :xxl="20">
            <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" />
        </a-col>
    </a-row>

</template>

<script>

import { ref } from 'vue'
import { Grid } from 'ant-design-vue';

const items = ref([
    {
        key: 'home',
        label: '首页',
        title: '首页',
    },
    {
        key: 'project',
        label: '案例',
        title: '案例',
    },
    {
        key: 'document',
        label: '资料',
        title: '资料',
        children: [
            {
                key: 'manual',
                label: '用户手册',
                title: '用户手册',
            },
            {
                key: 'video',
                label: '视频教程',
                title: '视频教程',
            },
            {
                key: 'download',
                label: '下载',
                title: '下载',
            },
        ],
    },
    {
        key: 'ai',
        label: 'AI助手',
        title: 'AI助手',
    },
    {
        key: 'contact',
        label: '交流',
        title: '交流',
    },

]);
const useBreakpoint = Grid.useBreakpoint;
export default {
    name: 'HeaderCard',
    components: {

    },
    data() {
        var screens = useBreakpoint();
        return {
            items,
            screens: screens,
        }
    }
}
</script>