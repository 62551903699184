<!-- Home.vue -->
<template>
    <HomeFirstCard />
    <HomeSecondCard />
</template>

<script>

import HomeFirstCard from '../components/HomeFisrtCard.vue';
import HomeSecondCard from '../components/HomeSecondCard.vue';

export default {
    name: 'HomeView',
    components: {
        HomeFirstCard,
        HomeSecondCard,
    },
    data() {
        return {
        }
    }
}
</script>
<style>
#main {
    background: url(../assets/banner-bg.png), linear-gradient(90deg, #eae6ff, #e7efff);
    background-size: cover;
}

.btnDownload:hover {
    /* todo:需要设置按钮悬停时样式 */
    background-color: #6a50e7;
}
</style>