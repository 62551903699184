<template>
    <a-row id="main" :gutter="16" justify="center" align="middle"
        style="padding-top: 30px;padding-bottom: 30px;padding-left: 24px;padding-right: 24px;">
        <a-col style="max-width: 550px; padding-right: 28px;" :span="4" :xs="24" :sm="24" :md="11" :lg="11" :xl="11"
            :xxl="11">
            <div style="padding-top: 18px;">

                <h2 style="margin: 0;text-align: left;margin-bottom: 16px;">
                    更适合
                    <mark style="padding: 0;
    color: #7f66ff;
    background-color: transparent; white-space: nowrap;">工厂用户</mark>
                    的
                    <br />
                    机器视觉平台
                </h2>
                <p
                    style="margin: 0;text-align: left;max-width: 460px;line-height: 2rem;color: rgba(25, 20, 51, 0.8);font-weight: 400; font-size: 1.125rem;margin-bottom:32px">
                    相比于传统视觉软件，<mark style="padding: 0;
    color: #7f66ff;
    background-color: transparent; white-space: nowrap;">AiSight</mark>更贴近工厂用户的实际需求，提供适用于各种行业的案例、模板和专用工具，提供全面的服务与支持。
                </p>
                <div style="margin: 0;text-align: left;margin-bottom: 16px; justify-content: flex-start">
                    <a-space :size="'large'">
                        <a-button class="btnDownload" type="primary" @click="DownloadNew()"
                            style="width: 160px; height: 48px; background-color:#7f66ff;">
                            <template #icon>
                                <DownloadOutlined />
                            </template>
                            免费下载
                        </a-button>
                        <a-button style="width: 160px; height: 48px; color: #7f66ff; ">方案咨询</a-button>
                    </a-space>

                </div>
            </div>
        </a-col>
        <a-col :span="20" :xs="24" :sm="24" :md="13" :lg="13" :xl="13" :xxl="13" style="max-width: 650px;">
            <div style="box-shadow: 0 8px 80px #7f66ff; width: 96%;margin-left: 2%;border-radius: 6px;">
                <img src="../assets/main.png" style="width: 100%;vertical-align:top;border-radius: 6px;" />
            </div>
        </a-col>
    </a-row>

</template>

<script>
import { DownloadOutlined } from '@ant-design/icons-vue';


export default {
    name: 'HomeFisrtCard',
    components: {
        DownloadOutlined,
    },
    methods: {
        DownloadNew() {
            window.open('./download/AiSight.zip', '_blank');
        }
    }
}
</script>